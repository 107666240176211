import isEqual from 'lodash.isequal'
import { orderTypes } from '../../utils/Constants'
import {
  RESET,
  SET_COOKIE,
  SET_LOCATION,
  SET_CART,
  SET_SHOP_FOR_CART,
  SET_MEAL_TYPE_FOR_CART,
  SET_MEAL_DAY_FOR_CART,
  SET_ORDER_TYPE_FOR_CART,
  SET_PICKUP_START_FOR_CART,
  SET_DELIVERY_LOCATION_FOR_CART,
  ADD_MEAL_TO_CART,
  ADD_SIDE_TO_CART,
  CHANGE_QUANTITY_FOR_MEAL_IN_CART,
  CHANGE_QUANTITY_FOR_SIDE_IN_CART,
  CLEAR_CART,
  SET_CANCELATION_SURVEY,
  SHOW_DIALOG,
  CLOSE_DIALOG,
  SET_DEFAULT_OFFER_CODE,
} from './actions'

const getInitialState = () => ({
  cart: {
    orderType: orderTypes.REGULAR,
    meals: [],
    sides: [],
  },
  dialog: null,
})

export default (state = getInitialState(), action) => {
  switch (action.type) {
    case RESET: {
      return getInitialState()
    }
    case SET_COOKIE: {
      return {
        ...state,
        cookie: action.cookie,
      }
    }
    case SET_LOCATION: {
      return {
        ...state,
        location: action.location,
      }
    }
    case SET_CART: {
      return {
        ...state,
        cart: action.cart,
      }
    }
    case SET_SHOP_FOR_CART: {
      return {
        ...state,
        cart: {
          ...state.cart,
          shop: action.shop,
        }
      }
    }
    case SET_MEAL_TYPE_FOR_CART: {
      return {
        ...state,
        cart: {
          ...state.cart,
          mealType: action.mealType,
        }
      }
    }
    case SET_MEAL_DAY_FOR_CART: {
      return {
        ...state,
        cart: {
          ...state.cart,
          mealDay: action.mealDay,
        }
      }
    }
    case SET_ORDER_TYPE_FOR_CART: {
      return {
        ...state,
        cart: {
          ...state.cart,
          orderType: action.orderType,
        }
      }
    }
    case SET_PICKUP_START_FOR_CART: {
      return {
        ...state,
        cart: {
          ...state.cart,
          pickupStartsAt: action.pickupStartsAt,
        }
      }
    }
    case SET_DELIVERY_LOCATION_FOR_CART: {
      return {
        ...state,
        cart: {
          ...state.cart,
          deliveryLocation: action.deliveryLocation,
        }
      }
    }
    case ADD_MEAL_TO_CART: {
      const meals = [...state.cart.meals]
      const existingMealIndex = meals.findIndex(meal => meal.id === action.meal.id && isEqual(meal.options, action.meal.options))

      if (existingMealIndex >= 0) {
        meals[existingMealIndex].quantity += action.meal.quantity
      } else {
        meals.push({
          id: action.meal.id,
          name: action.meal.name,
          images: action.meal.images,
          pickupInstructions: action.meal.pickupInstructions,
          options: action.meal.options,
          ticketQuantity: action.meal.ticketQuantity,
          quantity: action.meal.quantity,
          amountRemaining: action.meal.amountRemaining,
        })
      }

      return {
      ...state,
        cart: {
          ...state.cart,
          meals,
        }
      }
    }
    case ADD_SIDE_TO_CART: {
      return {
        ...state,
        cart: {
          ...state.cart,
          sides: [...state.cart.sides, {
            id: action.side.id,
            name: action.side.name,
            images: action.side.images,
            quantity: action.side.quantity,
          }],
        }
      }
    }
    case CHANGE_QUANTITY_FOR_MEAL_IN_CART: {
      return {
        ...state,
        cart: {
          ...state.cart,
          meals: state.cart.meals.map(meal => {
            if (meal.id === action.mealId) {
              return {
                ...meal,
                quantity: action.quantity,
              }
            }

            return meal
          }),
        }
      }
    }
    case CHANGE_QUANTITY_FOR_SIDE_IN_CART: {
      return {
        ...state,
        cart: {
          ...state.cart,
          sides: state.cart.sides.map(side => {
            if (side.id === action.sideId) {
              return {
                ...side,
                quantity: action.quantity,
              }
            }

            return side
          }),
        }
      }
    }
    case CLEAR_CART: {
      const initialState = getInitialState()

      return {
        ...state,
        cart: initialState.cart,
      }
    }
    case SET_CANCELATION_SURVEY: {
      return {
        ...state,
        cancelationSurvey: action.cancelationSurvey,
      }
    }
    case SHOW_DIALOG: {
      return {
        ...state,
        dialog: {
          isOpen: true,
          ...action.dialog,
        },
      }
    }
    case CLOSE_DIALOG: {
      return {
        ...state,
        dialog: null,
      }
    }
    case SET_DEFAULT_OFFER_CODE: {
      return {
        ...state,
        defaultOfferCode: action.defaultOfferCode,
      }
    }
    default:
      return state
  }
}
