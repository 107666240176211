import {
  createStore,
  combineReducers,
  applyMiddleware,
} from 'redux'
import {
  createWrapper,
  HYDRATE,
} from 'next-redux-wrapper'
import thunk from 'redux-thunk'
import api from './api/reducer'
import features from './features/reducer'

const dataReducers = combineReducers({
  api,
  features,
})

const reducer = (state = {}, action) => {
  if (action.type === HYDRATE) {
    return {
      ...state,
      ...action.payload,
    }
  }

  return dataReducers(state, action)
}

// create a makeStore function
const makeStore = context => createStore(reducer, applyMiddleware(thunk))

// export an assembled wrapper
export const wrapper = createWrapper(makeStore)
