export default {
  getInfo: () => {
    let server = !process.browser
    let os = 'other'

    if (!server) {
      const standalone = window.navigator.standalone
      const userAgent = window.navigator.userAgent.toLowerCase()
      const ios = /iphone|ipod|ipad/.test(userAgent)
      const android = /android/.test(userAgent)

      if (ios) {
        os = 'ios'
      } else if (android) {
        os = 'android'
      }
    }

    return {
      server,
      os,
    }
  }
}
