import * as React from "react"

function SvgComponent({ color='#B0B4BA', ...rest }) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M20.5 2h-16c-1.1 0-1.99.9-1.99 2L2.5 22l4-4h14c1.1 0 2-.9 2-2V4c0-1.1-.9-2-2-2zm-14 7h12v2h-12V9zm8 5h-8v-2h8v2zm4-6h-12V6h12v2z"
        fill={color}
      />
    </svg>
  )
}

export default SvgComponent
