import * as React from "react"

function SvgComponent({ color='#B0B4BA', containerStrokeWidth=4, ...rest }) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <mask
        id="prefix__a"
        maskUnits="userSpaceOnUse"
        x={12.084}
        y={4.76}
        width={8}
        height={9}
        fill="#000"
      >
        <path fill="#fff" d="M12.084 4.76h8v9h-8z" />
        <path d="M16.06 5.76h.02c1.939.067 2.956 2.64 2.956 3.993.03 1.527-1.315 2.89-2.947 2.976h-.057c-1.623-.086-2.976-1.45-2.948-2.985 0-1.354 1.018-3.917 2.957-3.984h.02zm0 5.846c1.008-.067 1.882-.921 1.863-1.843 0-.998-.768-2.832-1.863-2.89-1.094.058-1.862 1.892-1.862 2.88-.01.932.854 1.796 1.862 1.853z" />
      </mask>
      <path
        d="M16.06 5.76h.02c1.939.067 2.956 2.64 2.956 3.993.03 1.527-1.315 2.89-2.947 2.976h-.057c-1.623-.086-2.976-1.45-2.948-2.985 0-1.354 1.018-3.917 2.957-3.984h.02zm0 5.846c1.008-.067 1.882-.921 1.863-1.843 0-.998-.768-2.832-1.863-2.89-1.094.058-1.862 1.892-1.862 2.88-.01.932.854 1.796 1.862 1.853z"
        fill={color}
      />
      <path
        d="M16.06 5.76h.02c1.939.067 2.956 2.64 2.956 3.993.03 1.527-1.315 2.89-2.947 2.976h-.057c-1.623-.086-2.976-1.45-2.948-2.985 0-1.354 1.018-3.917 2.957-3.984h.02zm0 5.846c1.008-.067 1.882-.921 1.863-1.843 0-.998-.768-2.832-1.863-2.89-1.094.058-1.862 1.892-1.862 2.88-.01.932.854 1.796 1.862 1.853z"
        stroke={color}
        strokeWidth={0.4}
        mask="url(#prefix__a)"
      />
      <mask
        id="prefix__b"
        maskUnits="userSpaceOnUse"
        x={13.484}
        y={10.702}
        width={5}
        height={9}
        fill="#000"
      >
        <path fill="#fff" d="M13.484 10.702h5v9h-5z" />
        <path d="M15.224 11.702h.02c.306.01.546.269.537.576l-.183 5.76c0 .058.03.096.058.134.086.096.24.145.403.145a.605.605 0 00.403-.145.19.19 0 00.058-.124l-.182-5.76a.558.558 0 01.537-.576c.298-.03.567.23.576.537l.183 5.76a1.28 1.28 0 01-.375.941c-.297.307-.739.49-1.2.49-.46 0-.902-.173-1.2-.49a1.304 1.304 0 01-.374-.94l.182-5.76a.561.561 0 01.557-.548z" />
      </mask>
      <path
        d="M15.224 11.702h.02c.306.01.546.269.537.576l-.183 5.76c0 .058.03.096.058.134.086.096.24.145.403.145a.605.605 0 00.403-.145.19.19 0 00.058-.124l-.182-5.76a.558.558 0 01.537-.576c.298-.03.567.23.576.537l.183 5.76a1.28 1.28 0 01-.375.941c-.297.307-.739.49-1.2.49-.46 0-.902-.173-1.2-.49a1.304 1.304 0 01-.374-.94l.182-5.76a.561.561 0 01.557-.548z"
        fill={color}
      />
      <path
        d="M15.224 11.702h.02c.306.01.546.269.537.576l-.183 5.76c0 .058.03.096.058.134.086.096.24.145.403.145a.605.605 0 00.403-.145.19.19 0 00.058-.124l-.182-5.76a.558.558 0 01.537-.576c.298-.03.567.23.576.537l.183 5.76a1.28 1.28 0 01-.375.941c-.297.307-.739.49-1.2.49-.46 0-.902-.173-1.2-.49a1.304 1.304 0 01-.374-.94l.182-5.76a.561.561 0 01.557-.548z"
        stroke={color}
        strokeWidth={0.4}
        mask="url(#prefix__b)"
      />
      <mask
        id="prefix__c"
        maskUnits="userSpaceOnUse"
        x={5.738}
        y={10.702}
        width={5}
        height={9}
        fill="#000"
      >
        <path fill="#fff" d="M5.738 10.702h5v9h-5z" />
        <path d="M7.478 11.702h.02c.306.01.546.269.537.576l-.183 5.76c0 .058.03.096.058.134.086.096.24.145.403.145a.605.605 0 00.403-.145c.03-.028.068-.076.058-.124l-.182-5.76a.558.558 0 01.537-.576c.317-.03.567.23.576.537l.183 5.76c.01.346-.116.682-.375.941-.297.307-.739.49-1.2.49-.46 0-.902-.173-1.2-.49a1.304 1.304 0 01-.374-.94l.182-5.76a.561.561 0 01.557-.548z" />
      </mask>
      <path
        d="M7.478 11.702h.02c.306.01.546.269.537.576l-.183 5.76c0 .058.03.096.058.134.086.096.24.145.403.145a.605.605 0 00.403-.145c.03-.028.068-.076.058-.124l-.182-5.76a.558.558 0 01.537-.576c.317-.03.567.23.576.537l.183 5.76c.01.346-.116.682-.375.941-.297.307-.739.49-1.2.49-.46 0-.902-.173-1.2-.49a1.304 1.304 0 01-.374-.94l.182-5.76a.561.561 0 01.557-.548z"
        fill={color}
      />
      <path
        d="M7.478 11.702h.02c.306.01.546.269.537.576l-.183 5.76c0 .058.03.096.058.134.086.096.24.145.403.145a.605.605 0 00.403-.145c.03-.028.068-.076.058-.124l-.182-5.76a.558.558 0 01.537-.576c.317-.03.567.23.576.537l.183 5.76c.01.346-.116.682-.375.941-.297.307-.739.49-1.2.49-.46 0-.902-.173-1.2-.49a1.304 1.304 0 01-.374-.94l.182-5.76a.561.561 0 01.557-.548z"
        stroke={color}
        strokeWidth={0.4}
        mask="url(#prefix__c)"
      />
      <mask
        id="prefix__d"
        maskUnits="userSpaceOnUse"
        x={4.279}
        y={4.76}
        width={8}
        height={8}
        fill="#000"
      >
        <path fill="#fff" d="M4.279 4.76h8v8h-8z" />
        <path d="M5.855 5.76c.307 0 .557.25.557.557v3.158c0 1.046.854 1.9 1.9 1.9 1.047 0 1.902-.854 1.902-1.9V6.317a.557.557 0 011.113 0v3.158a3.03 3.03 0 01-3.024 3.024A3.03 3.03 0 015.28 9.475V6.317a.587.587 0 01.576-.557z" />
      </mask>
      <path
        d="M5.855 5.76c.307 0 .557.25.557.557v3.158c0 1.046.854 1.9 1.9 1.9 1.047 0 1.902-.854 1.902-1.9V6.317a.557.557 0 011.113 0v3.158a3.03 3.03 0 01-3.024 3.024A3.03 3.03 0 015.28 9.475V6.317a.587.587 0 01.576-.557z"
        fill={color}
      />
      <path
        d="M5.855 5.76c.307 0 .557.25.557.557v3.158c0 1.046.854 1.9 1.9 1.9 1.047 0 1.902-.854 1.902-1.9V6.317a.557.557 0 011.113 0v3.158a3.03 3.03 0 01-3.024 3.024A3.03 3.03 0 015.28 9.475V6.317a.587.587 0 01.576-.557z"
        stroke={color}
        strokeWidth={0.4}
        mask="url(#prefix__d)"
      />
      <mask
        id="prefix__e"
        maskUnits="userSpaceOnUse"
        x={7.582}
        y={4.76}
        width={3}
        height={5}
        fill="#000"
      >
        <path fill="#fff" d="M7.582 4.76h3v5h-3z" />
        <path d="M9.139 5.76c.307 0 .557.25.557.557v2.332a.557.557 0 01-1.114 0V6.317c0-.308.25-.557.557-.557z" />
      </mask>
      <path
        d="M9.139 5.76c.307 0 .557.25.557.557v2.332a.557.557 0 01-1.114 0V6.317c0-.308.25-.557.557-.557z"
        fill={color}
      />
      <path
        d="M9.139 5.76c.307 0 .557.25.557.557v2.332a.557.557 0 01-1.114 0V6.317c0-.308.25-.557.557-.557z"
        stroke={color}
        strokeWidth={0.4}
        mask="url(#prefix__e)"
      />
      <mask
        id="prefix__f"
        maskUnits="userSpaceOnUse"
        x={5.939}
        y={4.76}
        width={3}
        height={5}
        fill="#000"
      >
        <path fill="#fff" d="M5.939 4.76h3v5h-3z" />
        <path d="M7.496 5.76c.307 0 .557.25.557.557v2.332a.557.557 0 01-1.114 0V6.317c0-.308.25-.557.557-.557z" />
      </mask>
      <path
        d="M7.496 5.76c.307 0 .557.25.557.557v2.332a.557.557 0 01-1.114 0V6.317c0-.308.25-.557.557-.557z"
        fill={color}
      />
      <path
        d="M7.496 5.76c.307 0 .557.25.557.557v2.332a.557.557 0 01-1.114 0V6.317c0-.308.25-.557.557-.557z"
        stroke={color}
        strokeWidth={0.4}
        mask="url(#prefix__f)"
      />
      <mask id="prefix__g" fill="#fff">
        <path d="M22.878 2.299a1.853 1.853 0 00-1.329-1.293C17.226-.088 13.355.002 12 .002c-1.355 0-5.226-.09-9.55 1.004a1.853 1.853 0 00-1.328 1.293C-.087 6.578.002 10.006.002 12c0 1.993-.089 5.422 1.12 9.701.18.638.687 1.131 1.329 1.293 4.323 1.094 8.194 1.004 9.549 1.004 1.355 0 5.226.09 9.55-1.004a1.853 1.853 0 001.328-1.293c1.209-4.279 1.12-7.707 1.12-9.701 0-1.995.089-5.422-1.12-9.701z" />
      </mask>
      <path
        d="M22.878 2.299a1.853 1.853 0 00-1.329-1.293C17.226-.088 13.355.002 12 .002c-1.355 0-5.226-.09-9.55 1.004a1.853 1.853 0 00-1.328 1.293C-.087 6.578.002 10.006.002 12c0 1.993-.089 5.422 1.12 9.701.18.638.687 1.131 1.329 1.293 4.323 1.094 8.194 1.004 9.549 1.004 1.355 0 5.226.09 9.55-1.004a1.853 1.853 0 001.328-1.293c1.209-4.279 1.12-7.707 1.12-9.701 0-1.995.089-5.422-1.12-9.701z"
        stroke={color}
        strokeWidth={containerStrokeWidth}
        mask="url(#prefix__g)"
      />
    </svg>
  )
}

export default SvgComponent
