import * as React from "react"

function SvgComponent({ color='#B0B4BA', ...rest }) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M12.501 23a55.96 55.96 0 01-8.61-.677c-1.093-.203-2.029-1.238-2.146-2.35l-.234-2.39c-.107-1.092.575-2.35 1.57-2.872a21.758 21.758 0 015.822-1.809l-.03-.222c-.545-.455-.896-1.035-.955-1.606l-.059-.56a1.373 1.373 0 01-.838-1.142l-.234-2.244a1.342 1.342 0 01.292-1.006c.078-.087.156-.164.244-.232l-.078-.677c-.068-.764-.244-2.534 1.15-3.762C9.323.54 10.835 0 12.502 0c1.668 0 3.18.542 4.145 1.48 1.355 1.2 1.19 2.97 1.111 3.724l-.019.183-.058.494c.087.067.165.145.234.222.234.29.34.648.302 1.025l-.224 2.254c-.05.512-.38.948-.84 1.141l-.067.59c-.04.532-.39 1.122-.937 1.586l-.029.223a21.58 21.58 0 015.783 1.79c1.024.531 1.716 1.788 1.61 2.891l-.235 2.38c-.117 1.121-1.053 2.156-2.126 2.35a57.985 57.985 0 01-8.65.667zM8.893 8.58c.049 0 .098.009.137.028.36.116.624.435.663.803.058.493.117.977.166 1.47.02.058.156.27.438.455.244.154.41.406.449.686l.224 1.567a.924.924 0 01-.195.726.976.976 0 01-.663.367c-2.165.261-4.232.86-6.153 1.76-.293.155-.537.668-.507.958l.234 2.37c.029.29.331.609.536.648 5.432.86 11.088.87 16.568 0 .186-.04.488-.349.517-.639l.234-2.37c.03-.29-.214-.802-.536-.967a19.92 19.92 0 00-6.134-1.74.955.955 0 01-.653-.368 1.006 1.006 0 01-.195-.726l.224-1.576a.979.979 0 01.439-.677c.283-.184.42-.387.439-.455l.175-1.48a.977.977 0 01.663-.802.724.724 0 01.137-.039l.117-1.074a.633.633 0 01-.156-.087.962.962 0 01-.42-.909l.01-.096c.049-.397.098-.784.137-1.18l.02-.194c.067-.754.097-1.596-.508-2.128-.605-.628-1.648-.977-2.799-.977-1.14 0-2.184.349-2.779.929-.634.56-.605 1.412-.536 2.166l.166 1.48a.943.943 0 01-.42.91c-.049.028-.097.057-.146.077l.107 1.083z"
        fill={color}
      />
    </svg>
  )
}

export default SvgComponent
