import {
  getNearbyLocations,
  getDeliveryLocationsIfNeeded,
} from '../redux/api/actions'
import {
  setLocation,
} from '../redux/features/actions'
import {
  DEFAULT_LOCATION,
} from './Constants'
import Device from './Device'

const TIMEOUT = 10 * 1000 // 10 seconds
const MAXIMUM_AGE = 5 * 60 * 1000 // 5 minutes

const FAKE_LOCATION = {
  coords: {
    latitude: 35.682080,
    longitude: 139.762026,
  },
}

const browserGetUserLocation = () => new Promise((resolve, reject) => window?.navigator?.geolocation?.getCurrentPosition(resolve, reject, {
  timeout: TIMEOUT,
  maximumAge: MAXIMUM_AGE,
  enableHighAccuracy: true,
}))

// const browserGetUserLocation = () => FAKE_LOCATION

const loadLocation = async dispatch => {
  let location
  let deliveryLocations = []

  try {
    deliveryLocations = await dispatch(getDeliveryLocationsIfNeeded())
  } catch (exception) {
    console.log(exception)
  }

  if (deliveryLocations?.length > 0) {
    location = deliveryLocations[0]
  } else {
    const deviceInfo = Device.getInfo()

    let deviceLocation

    try {
      deviceLocation = await browserGetUserLocation()
    } catch (exception) {
      console.log(exception)
    }

    if (deviceLocation) {
      const nearbyPlaces = await dispatch(getNearbyLocations({
        latitude: deviceLocation.coords.latitude,
        longitude: deviceLocation.coords.longitude,
      }))

      if (nearbyPlaces.length > 0) {
        location = nearbyPlaces[0]
      }
    }
  }

  if (!location) {
    location = DEFAULT_LOCATION
  }

  dispatch(setLocation(location))

  return location
}

export default loadLocation
