import App from 'next/app'
import Head from 'next/head'
import Router, {
  withRouter,
} from 'next/router'
import {
  ThemeProvider,
} from 'react-jss'
import {
  connect,
} from 'react-redux'
import {
  bindActionCreators,
} from 'redux'
import {
  GoogleFonts,
} from 'next-google-fonts'
import {
  wrapper,
} from '../redux/store'
import {
  reset,
  setCookie,
  setLocation,
} from '../redux/features/actions'
import {
  logout,
  getProfile,
  getSavedLocations,
} from '../redux/api/actions'
import Bugsnag from '../utils/Bugsnag'
import Channel from '../utils/Channel'
import loadLocation from '../utils/loadLocation'
import promiseMap from '../utils/promiseMap'
import sleep from '../utils/sleep'
import Theme from '../utils/theme'
import {
  DEFAULT_LOCATION,
} from '../utils/Constants'
import ErrorPage from './_error'
import AvatarMenu from '../components/AvatarMenu'
import HomeIcon from '../components/icons/nav/HomeIcon'
import OrdersIcon from '../components/icons/nav/OrdersIcon'
import SupportIcon from '../components/icons/nav/SupportIcon'
import ProfileIcon from '../components/icons/nav/ProfileIcon'
import '../styles/globals.css'
import 'mapbox-gl/dist/mapbox-gl.css'

const MENU_ITEMS = [{
  route: '/menu',
  label: 'メニュー',
  icon: HomeIcon,
}, {
  route: '/orders',
  label: '履歴',
  icon: OrdersIcon,
}, {
  route: '/support',
  label: 'メッセージ',
  icon: SupportIcon,
}, {
  route: '/profile',
  label: 'マイページ',
  icon: ProfileIcon,
}]

const getSelectedMenuItem = pathname => MENU_ITEMS.find(menuItem => pathname.indexOf(menuItem.route) === 0)

const ErrorBoundary = Bugsnag.getPlugin('react')

const ErrorView = () => {
  <div>
    An error has occured.
  </div>
}

class WrappedApp extends App {
  onClickLogout = async () => {
    await this.props.logout()

    Channel.shutdown()

    window.location.href = '/'
  }

  componentDidMount() {
    if (process.browser && this.props.profile.data && !this.props.location) {
      loadLocation(this.props.dispatch)
    }
  }

  render() {
    const {
      Component,
      pageProps,
    } = this.props

    const selectedMenuItem = getSelectedMenuItem(this.props.router.pathname)

    return (
      <ErrorBoundary FallbackComponent={ErrorView}>
        <Head>
          <meta name="viewport" content="width=device-width, user-scalable=no" />
          <meta name="facebook-domain-verification" content="1fvkea78j23wdvu1gbdna9n7q7d3rl" />
          <script async src="https://www.googletagmanager.com/gtag/js?id=UA-116419988-1"></script>
          <script dangerouslySetInnerHTML={{
            __html: `
              window.dataLayer = window.dataLayer || [];
              function gtag(){dataLayer.push(arguments);}
              gtag('js', new Date());

              gtag('config', 'UA-116419988-1');
            `
          }} />
          <script dangerouslySetInnerHTML={{
            __html: `
              window.fbAsyncInit = function() {
                FB.init({
                  appId: '413568586131897',
                  cookie: true,
                  xfbml: true,
                  version: 'v11.0'
                });
                FB.AppEvents.logPageView();
              };

              (function(d, s, id){
                 var js, fjs = d.getElementsByTagName(s)[0];
                 if (d.getElementById(id)) {return;}
                 js = d.createElement(s); js.id = id;
                 js.src = "https://connect.facebook.net/en_US/sdk.js";
                 fjs.parentNode.insertBefore(js, fjs);
               }(document, 'script', 'facebook-jssdk'));
            `
          }} />
          <script dangerouslySetInnerHTML={{
            __html: `
              !function(f,b,e,v,n,t,s)
              {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
              n.callMethod.apply(n,arguments):n.queue.push(arguments)};
              if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
              n.queue=[];t=b.createElement(e);t.async=!0;
              t.src=v;s=b.getElementsByTagName(e)[0];
              s.parentNode.insertBefore(t,s)}(window, document,'script',
              'https://connect.facebook.net/en_US/fbevents.js');
              fbq('init', '3049976408656803');
              fbq('track', 'PageView');
            `
          }} />
          <noscript>
            <img
              height="1"
              width="1"
              style={{
                display: 'none',
              }}
              src="https://www.facebook.com/tr?id=3049976408656803&ev=PageView&noscript=1" />
          </noscript>
        </Head>
        <ThemeProvider theme={Theme}>
          <GoogleFonts href="https://fonts.googleapis.com/css2?family=Just+Another+Hand:wght@400;700&display=swap" />
          <Component {...pageProps} />
        </ThemeProvider>
      </ErrorBoundary>
    )
  }
}

const mapStateToProps = state => ({
  location: state.features.location,
  profile: state.api.profile.default,
  savedLocations: state.api.savedLocations.default,
})

const mapDispatchToProps = dispatch => ({
  ...bindActionCreators({
    reset,
    logout,
    setCookie,
    setLocation,
    getProfile,
  }, dispatch),
  dispatch,
})

export default wrapper.withRedux(connect(mapStateToProps, mapDispatchToProps)(withRouter(WrappedApp)))
