import {
  useState,
} from 'react'
import { createUseStyles } from 'react-jss'
import classNames from 'classnames'
import Grow from '@material-ui/core/Grow'
import Colors from '../utils/Colors'
import Avatar from './Avatar'

const useStyles = createUseStyles(theme => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
  },
  avatar: {
    boxShadow: '1px 1px 4px 0px rgba(0, 0, 0, 0.22)',
  },
  menu: {
    minWidth: 180,
    backgroundColor: Colors.white,
    boxShadow: '1px 1px 4px 0px rgba(0, 0, 0, 0.22)',
    borderRadius: 6,
    marginTop: theme.spacing.unit * 2,
  },
  divider: {
    borderBottom: `1px solid ${Colors.borderGray}`,
    marginLeft: theme.spacing.unit * 2,
    marginRight: theme.spacing.unit * 2,
  },
  menuHeader: {
    padding: theme.spacing.unit * 2,
  },
  menuTitle: {
    fontSize: 16,
    fontWeight: '600',
  },
  menuSubtitle: {
    fontSize: 12,
    fontWeight: '300',
  },
  menuItems: {
    paddingTop: theme.spacing.unit,
    paddingBottom: theme.spacing.unit,
  },
  menuItem: {
    fontSize: 14,
    fontWeight: '600',
    color: Colors.primaryGray,
    display: 'flex',
    alignItems: 'center',
    paddingLeft: theme.spacing.unit * 2,
    paddingRight: theme.spacing.unit * 2,
    paddingTop: theme.spacing.unit,
    paddingBottom: theme.spacing.unit,
    cursor: 'pointer',
  },
  menuItemSelected: {
    color: Colors.primaryPink,
  },
  menuIcon: {
    overflow: 'visible',
    height: 24,
    width: 24,
    marginRight: theme.spacing.unit,
  },
  menuFooter: {

  },
  logout: {
    padding: theme.spacing.unit * 2,
    color: Colors.primaryGray,
    textAlign: 'center',
    cursor: 'pointer',
  },
}))

const AvatarMenu = ({
  menuItems,
  selectedMenuItem,
  profile,
  className,
  style,
  onClickMenuItem,
  onClickLogout,
}) => {
  const classes = useStyles()
  const [open, setOpen] = useState(false)

  const onClickAvatar = () => {
    setOpen(!open)
  }

  const _onClickMenuItem = menuItem => {
    setOpen(false)
    onClickMenuItem && onClickMenuItem(menuItem)
  }

  return (
    <div
      style={style}
      className={classNames(classes.container, className)}>
      <Avatar
        profile={profile}
        className={classes.avatar}
        onClick={onClickAvatar} />
      <Grow
        mountOnEnter
        unmountOnExit
        in={open}>
        <div className={classes.menu}>
          <div className={classes.menuHeader}>
            <div className={classes.menuTitle}>
              {profile.name}
            </div>
            <div className={classes.menuSubtitle}>
              {profile.email || profile.phone}
            </div>
          </div>
          <div className={classes.divider} />
          <div className={classes.menuItems}>
            {menuItems.map(menuItem => (
              <div
                key={menuItem.key}
                className={classNames(classes.menuItem, {
                  [classes.menuItemSelected]: menuItem.path === selectedMenuItem?.path,
                })}
                onClick={() => _onClickMenuItem(menuItem)}>
                <menuItem.icon
                  color={menuItem.path === selectedMenuItem?.path ? Colors.primaryPink : Colors.primaryGray}
                  containerStrokeWidth={2}
                  className={classes.menuIcon} />
                {menuItem.label}
              </div>
            ))}
          </div>
          <div className={classes.divider} />
          <div className={classes.menuFooter}>
            <div
              className={classes.logout}
              onClick={onClickLogout}>
              ログアウト
            </div>
          </div>
        </div>
      </Grow>
    </div>
  )
}

export default AvatarMenu
