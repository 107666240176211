import * as React from "react"

function SvgComponent({ color='#B0B4BA', ...rest }) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M4.961 22.895c-.666.318-1.461-.125-1.461-.813V2.77C3.5 1.754 4.479 1 5.607 1h13.786c1.128 0 2.107.754 2.107 1.769v19.313c0 .688-.795 1.13-1.461.813l-2.91-1.385a2 2 0 00-1.72 0l-2.049.976a2 2 0 01-1.72 0l-2.05-.976a2 2 0 00-1.72 0l-2.909 1.385z"
        stroke={color}
        strokeWidth={2}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.3 8c-.442 0-.8-.447-.8-1 0-.553.358-1 .8-1h6.4c.442 0 .8.447.8 1 0 .553-.358 1-.8 1H9.3zM9.3 12c-.442 0-.8-.447-.8-1 0-.553.358-1 .8-1h6.4c.442 0 .8.447.8 1 0 .553-.358 1-.8 1H9.3zM9.3 16c-.442 0-.8-.447-.8-1 0-.553.358-1 .8-1h6.4c.442 0 .8.447.8 1 0 .553-.358 1-.8 1H9.3z"
        fill={color}
      />
    </svg>
  )
}

export default SvgComponent
