import { createUseStyles } from 'react-jss'
import classNames from 'classnames'
import ButtonBase from '@material-ui/core/ButtonBase'
import Colors from '../utils/Colors'

const DEFAULT_AVATAR = 'https://potluck-webapp.s3-ap-northeast-1.amazonaws.com/default_avatars/yellow.jpg'

const sizes = {
  small: 48,
  medium: 60,
  large: 80,
}

const useStyles = createUseStyles(theme => ({
  container: {
  },
  content: {
    height: '100%',
    width: '100%',
    border: `3px solid ${Colors.white}`,
    overflow: 'hidden',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  }
}))

export default ({ size='medium', profile, className, onClick }) => {
  const classes = useStyles()

  let avatarSource = DEFAULT_AVATAR

  if (profile?.photo?.uuid) {
    avatarSource = `https://potluck-media.s3-ap-northeast-1.amazonaws.com/${profile?.photo?.uuid}/small.jpg`
  }

  const sizePx = sizes[size]

  if (onClick) {
    return (
      <ButtonBase
        classes={{
          root: classNames(classes.container, className),
        }}
        style={{
          height: sizePx,
          width: sizePx,
          borderRadius: sizePx / 2,
        }}
        onClick={onClick}>
        <div
          className={classNames(classes.content, className)}
          style={{
            backgroundImage: `url(${avatarSource})`,
            borderRadius: sizePx / 2,
          }} />
      </ButtonBase>
    )
  }

  return (
    <div
      className={classNames(classes.container, className)}
      style={{
        height: sizePx,
        width: sizePx,
        borderRadius: sizePx / 2,
      }}>
      <div
        className={classes.content}
        style={{
          backgroundImage: `url(${avatarSource})`,
          borderRadius: sizePx / 2,
        }} />
    </div>
  )
}
